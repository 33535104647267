import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createPinia } from "pinia";
import "./main.css";
import kzLang from "./lang/kz.json";
import ruLang from "./lang/ru.json";
import enLang from "./lang/en.json";

import { createMetaManager } from "vue-meta";

import installElementPlus from "./plugins/element";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/dist/index.css";

import { createI18n } from "vue-i18n";

const i18n = createI18n({
  locale: "ru",
  legacy: false,
  fallbackLocale: "ru",
  messages: {
    kz: kzLang,
    ru: ruLang,
    en: enLang,
  },
});

const pinia = createPinia();
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

installElementPlus(app);
app.use(store).use(router).use(createMetaManager()).use(pinia).use(i18n);
await router.isReady();
app.mount("#app");
