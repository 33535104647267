<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | KazDams` : `KazDams`
    }}</template>
  </metainfo>
  <router-view></router-view>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
