import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://kazdams.kz/api",
});

export const setupAxios = () => {
  console.log("should be setted");
  apiClient.interceptors.request.use(
    (config) => {
      console.log("here should be setted");
      const token = localStorage.getItem("token");

      console.log(token, "it is token");

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
        console.log(config.headers["Authorization"]);
      }

      return config;
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );

  apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log(error);
      if ([401, 400].includes(error.response.status)) {
        console.log(error.response.status);
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
        window.location = "/login";

        return Promise.reject(error);
      }
    }
  );

  return apiClient;
};

export default apiClient;
