import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const FirstPage = () => import("../views/FirstPage.vue");
const MapView = () => import("../views/mapDir/mapIndex");
const DamItem = () => import("../views/mapDir/damItem.vue");

const LoginPage = () => import("../views/auth/loginPage.vue");
const RegisterPage = () => import("../views/auth/registerPage.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        name: "firstPage",
        path: "",
        component: FirstPage,
      },
      {
        path: "/map",
        name: "map",
        component: MapView,
        meta: { requiresAuth: true },
        children: [
          {
            path: ":damId",
            name: "damItem",
            component: DamItem,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    component: LoginPage,
    name: "LoginPage",
  },
  {
    path: "/register",
    component: RegisterPage,
    name: "RegisterPage",
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

const isAuthenticated = () => {
  let token = localStorage.getItem("token");
  if (token) {
    return true;
  } else {
    return false;
  }
};

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(
      (record) => record && record.meta && record.meta.requiresAuth
    )
  ) {
    if (isAuthenticated()) {
      next();
    } else {
      next({ name: "LoginPage" });
    }
  } else {
    next();
  }
});

export default router;
