<template>
  <router-view></router-view>
</template>

<script setup>
import moment from "moment";
import { setupAxios } from "@/api/apiClient";
import { defineUserStore } from "@/store/user";
import { onBeforeMount, onMounted } from "vue";
import { getUser } from "@/api/user/getUser";
import { useRoute, useRouter } from "vue-router";
const store = defineUserStore();
const router = useRouter();
const route = useRoute();

onBeforeMount(async () => {
  if (route.meta?.requiresAuth) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("user_id");
    if (!token && !userId) {
      await router.push({ name: "LoginPage" });
    }
    console.log("we are should be here");
    setupAxios();
    if (userId) {
      let data = await getUser(userId);
      data && "user" in data && store.setUser(data?.user);
    }
  }
});
onMounted(async () => {
  moment.locale("ru");
  console.log(router.currentRoute.value.meta?.requiresAuth);

  // console.log(router.currentRoute);
});
</script>
