import { defineStore } from "pinia";

export const defineUserStore = defineStore("users", {
  state: () => ({
    user: {},
  }),
  getters: {
    getUser(state) {
      return state.user;
    },
  },
  actions: {
    setUser(user) {
      this.user = user;
    },
  },
});
